var oauth_data      = {
        'client_id': '986760099087-1fh0fgvjhlmpfvh2d9v64sou7v7fhfr8.apps.googleusercontent.com',
        'scopes': 'https://www.googleapis.com/auth/drive.readonly https://www.googleapis.com/auth/spreadsheets https://www.googleapis.com/auth/calendar'
    },
    drive_folder_id = '1ZnSzkcjG8Kx5D8tmy3Fm5JpD6D3xa3as',
    config          = {
        global: {
            entities: [],
            date: {
                year: new Date().getFullYear().toString(),
                month: new Date().getMonth() + 1
            }
        },
        current: {
            settings: {},
            data: {
                aggregated: {
                    categories: {},
                    methods: {},
                    days: {},
                    taxes: {
                        total: 0,
                        deposited: 0
                    },
                    totals: {
                        expenses: 0,
                        income: {
                            full: 0,
                            neto: 0
                        },
                        balance: 0
                    },
                    prev_transferred: {
                        sum: 0,
                        desc: ''
                    }
                }
            }
        }
    },
    months          = {
        '01': 'ינואר',
        '02': 'פברואר',
        '03': 'מרץ',
        '04': 'אפריל',
        '05': 'מאי',
        '06': 'יוני',
        '07': 'יולי',
        '08': 'אוגוסט',
        '09': 'ספטמבר',
        '10': 'אוקטובר',
        '11': 'נובמבר',
        '12': 'דצמבר'
    },
    days            = [
        'ראשון',
        'שני',
        'שלישי',
        'רביעי',
        'חמישי',
        'שישי',
        'שבת'
    ];

// Pad the month in the config.global.date object with a leading zero
config.global.date.month = config.global.date.month.toString().padStart(2, '0');

// Init the application (Google API callback)
function flow_init() {

    // Reauth with Google if there is no token cookie present
    if(! Cookies.get('flow-google-token') || Cookies.get('flow-google-token') === undefined) {
        render_google_onetap_login();
    } else { // If the cookie is present, just load the app
        load_data_and_build_app();
    }
}

// Renders the Google One-Tap login modal, but
// fallbacks to the regular dialog if it fails to load.
function render_google_onetap_login() {

    // Init and render the Google One-tap login modal
    google.accounts.id.initialize({
        client_id: oauth_data.client_id,
        callback: handle_user_login,
        ux_mode: 'popup'
    });

    google.accounts.id.prompt(function(notification) {

        // If the modal is not displayed for some reason,
        // call the handle_user_login callback which will redirect the user to
        // the login and permissions granting pages.
        if(notification.isNotDisplayed() || notification.isSkippedMoment()) {
            handle_user_login(null);
        }
    });
}

// Used to get the access token for the Google API actions
// (following a first time auth or if a refreshed token is needed)
function handle_user_login(data) {

    var client_config = {
        client_id: oauth_data.client_id,
        scope: oauth_data.scopes,
        prompt: '',
        error_callback: function() {
            window.reload();
        },
        callback: function(token_response) {

            // Save the token in a cookie that expires 5 seconds before the token expires
            Cookies.set('flow-google-token', token_response.access_token, { 
                expires: new Date(new Date().getTime() + (token_response.expires_in * 1000 - 5)), 
                path: '/' 
            });

            // Load the app and fetch the data for it
            load_data_and_build_app();
        }
    };

    // If this request follows the One Tap login request,
    // send a hint to Google with the email of the user for
    // which we are requesting the consent.
    if(data !== null) {
        client_config.hint = jwt_decode(data.credential).email;
    }

    client = google.accounts.oauth2.initTokenClient(client_config);
    client.requestAccessToken();
}

// Fetch and parse the data from Google Drive and Sheets,
// and use that data to load the app's components and elements.
function load_data_and_build_app() {

    // Load the app global configuration from Google Sheets
    fetch_global_app_config();

    // Load the app's entities list, and only load the data for the current set entity
    if(fetch_app_entity_data()) {

        // Aggregate and run calculations on the entity's data
        aggregate_and_calculate_entity_data();
    }

    // Build the app's layout and components
    build_app_and_components(function() {
        $('body').removeClass('is-loading');
        $('.flow-loader--general').hide();
    });
}

// Fetch and parse the global configuration from Google Sheets
function fetch_global_app_config() {

    $.ajax({
        url: 'https://www.googleapis.com/drive/v3/files?q=\'' + drive_folder_id + '\'+in+parents+and+name="Configuration"',
        type: 'GET',
        async: false,
        beforeSend: function(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + Cookies.get('flow-google-token'));
        },
        error: function(data) {
            console.log(data);
        },
        success: function(data) {
            
            if(data.files && data.files.length > 0) {
                fetch_google_sheet_contents_by_id(data.files[0].id, config.global);
            }
        }
    });
}

// Fetch and parse the entity configuration from Google Sheets
function fetch_app_entity_data() {

    var success = false;

    // First, fetch the entities list (folders)
    $.ajax({
        url: 'https://www.googleapis.com/drive/v3/files?q=\'' + drive_folder_id + '\'+in+parents+and+mimeType="application/vnd.google-apps.folder"',
        type: 'GET',
        async: false,
        beforeSend: function(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + Cookies.get('flow-google-token'));
        },
        error: function(data) {
            console.log(data);
        },
        success: function(data) {
            
            if(data.files && data.files.length > 0) {

                // Append the entities retrieved to the config.entities object
                $.each(data.files, function() {

                    config.global.entities.push({
                        name: this.name,
                        id: this.id
                    });
                });

                // Check which app is set to the current one from the flow-current-app cookie,
                // if no app was set, use the first one fetched from Google Drive and set it in the cookie.
                var current_app = Cookies.get('flow-current-app');

                if(current_app !== null && current_app !== undefined) {

                    $.each(data.files, function() {

                        if(this.name === current_app) {

                            // Fetch the configuration
                            fetch_app_entity_configuration(this.id);

                            // Locate the current year's and month's sheet and fetch it's data
                            fetch_app_entity_data_for_date(this.id, config.global.date.year, config.global.date.month);

                            // Set the entity name in the current object in the config
                            config.current.name = current_app;
                        }
                    });

                } else {

                    // Fetch the configuration
                    fetch_app_entity_configuration(data.files[0].id);

                    // Locate the current year's and month's sheet and fetch it's data
                    fetch_app_entity_data_for_date(data.files[0].id, config.global.date.year, config.global.date.month);

                    // Set the entity name to the one fetched in the current object in the config
                    config.current.name = data.files[0].name;

                    // Set the current app to the one fetched
                    Cookies.set('flow-current-app', data.files[0].name, { 
                        expires: 365, 
                        path: '/' 
                    });
                }

                success = true;
            }
        }
    });

    return success;
}

// Fetch and parse the entity configuration file
function fetch_app_entity_configuration(id) {

    $.ajax({
        url: 'https://www.googleapis.com/drive/v3/files?q=\'' + id + '\'+in+parents+and+name="Configuration"',
        type: 'GET',
        async: false,
        beforeSend: function(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + Cookies.get('flow-google-token'));
        },
        error: function(data) {
            console.log(data);
        },
        success: function(data) {
            
            if(data.files && data.files.length > 0) {
                
                // Fetch the contents and append to the current entity's settings object
                fetch_google_sheet_contents_by_id(data.files[0].id, config.current.settings);
            }
        }
    });
}

// Fetch and parse the data from an entity's data sheet for a specific year and month
function fetch_app_entity_data_for_date(id, year, month) {

    // First, fetch the year's sheet ID for the entity
    $.ajax({
        url: 'https://www.googleapis.com/drive/v3/files?q=\'' + id + '\'+in+parents+and+name="' + year + '"',
        type: 'GET',
        async: false,
        beforeSend: function(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + Cookies.get('flow-google-token'));
        },
        error: function(data) {
            console.log(data);
        },
        success: function(data) {

            if(data.files && data.files.length > 0) {
                
                // Fetch the contents and append to the current entity's settings object
                fetch_google_sheet_contents_by_id(data.files[0].id, config.current.data, month + '!A1:Z1001');
            }
        }
    });
}

// Take the entity data fetched and run the relevant aggregations
// and calculations needed by the app.
function aggregate_and_calculate_entity_data() {

    $.each(config.current.data[config.global.date.month], function(key) {
        
        // Skip canceled items
        if(this['סטטוס'] != 'בוטל') {

            // If the line is a sum transfer (from previous year / month),
            // append it to the total and the prev_transferred variables.
            if(this['קטגוריה ראשית'] == 'העברת יתרה') {
                config.current.data.aggregated.prev_transferred.sum  = Number(this['סכום']);
                config.current.data.aggregated.prev_transferred.desc = this['תיאור'].toString();
                config.current.data.aggregated.totals.balance        = Number(this['סכום']);

                // Also, unset the item from the current.data config object
                delete config.current.data[config.global.date.month][key];
            } 

            // If not, continue the calculation flow for regular rows
            else {

                // 1. Calculate the expenses
                if(this['זכות / חובה'] == 'חובה') {

                    // 1a. By category
                    if(! config.current.data.aggregated.categories[this['קטגוריה ראשית']] || config.current.data.aggregated.categories[this['קטגוריה ראשית']] === undefined) {

                        config.current.data.aggregated.categories[this['קטגוריה ראשית']] = {
                            total: 0,
                            deposited: 0
                        };
                    }

                    config.current.data.aggregated.categories[this['קטגוריה ראשית']].total += Number(this['סכום']);

                    // If it was deposited to savings, add to the deposited counter for that category
                    if(this['הופקד לפקדון?'] == 'כן') {
                        config.current.data.aggregated.categories[this['קטגוריה ראשית']].deposited += Number(this['סכום']);
                    }

                    // 1b. By payment method
                    if(! config.current.data.aggregated.methods[this['אמצעי תשלום']] || config.current.data.aggregated.methods[this['אמצעי תשלום']] === undefined) {
                        config.current.data.aggregated.methods[this['אמצעי תשלום']] = 0;
                    }

                    config.current.data.aggregated.methods[this['אמצעי תשלום']] += Number(this['סכום']);

                    // 1c. Total
                    config.current.data.aggregated.totals.expenses += Number(this['סכום']);
                } 
                
                // 2. Calculate the income
                else {

                    // 2a. Total (inc. taxes)
                    config.current.data.aggregated.totals.income.full += Number(this['סכום']);

                    // 2b. Only taxes
                    if(this['אחוז מס לתשלום'] != '') {

                        if(isNaN(this['אחוז מס לתשלום'])) { // If the tax rate is not a number but a variable, locate the related taxes in the tax_rates table and calculate

                            var item          = this,
                                tax_category  = item['אחוז מס לתשלום'],
                                taxed_amount  = 0,
                                remaining_sum = Number(this['סכום']);

                            $.each(config.current.settings.tax_rates, function() {

                                if(this.category == tax_category) {

                                    if(this.name == 'מע״מ') { // The prices entered include taxes, so we need a special clause for VAT which is already set in the sum
                                        taxed_amount += (remaining_sum / ((Number(this.percentage) / 100) + 1)) * Number(this.percentage) / 100;
                                    } else {
                                        taxed_amount += remaining_sum * (Number(this.percentage) / 100);
                                    }

                                    remaining_sum -= taxed_amount;
                                    config.current.data.aggregated.taxes.total += taxed_amount;
                                }
                            });

                            // Also, append the non-VAT value to the item
                            item['סכום לאחר מס'] = remaining_sum;

                        } else { // If it's a number, simply run the calculation
                            config.current.data.aggregated.taxes.total += Number(this['סכום']) * (Number(this['אחוז מס לתשלום']) / 100);
                        }

                        // If the taxes were deposited to savings, add it to the deposited counter
                        if(this['הופקד לפקדון?'] == 'כן') {

                            if(isNaN(this['אחוז מס לתשלום'])) { // If the tax rate is not a number but a variable, locate the related taxes in the tax_rates table and calculate

                                config.current.data.aggregated.taxes.deposited += taxed_amount;

                            } else { // If it's a number, simply run the calculation
                                config.current.data.aggregated.taxes.deposited += Number(this['סכום']) * (Number(this['אחוז מס לתשלום']) / 100);
                            }
                        }
                    }            
                }
            }
        }
    });

    // After finishing the base calculations: 
    // 1. Calculate the neto amount (income excluding taxes)
    config.current.data.aggregated.totals.income.neto = (
        config.current.data.aggregated.totals.income.full - config.current.data.aggregated.taxes.total
    );

    // 2. Calculate the current balance (neto income minus expenses)
    config.current.data.aggregated.totals.balance = (
        (config.current.data.aggregated.totals.balance + config.current.data.aggregated.totals.income.neto) - 
        config.current.data.aggregated.totals.expenses
    );
}

// Build the app and it's components
function build_app_and_components(callback) {

    // Fill the current entity in the header
    $('.flow-header__actions-menu_entity-meta').find('strong').html(config.current.name);
    
    // Place the other entities in the dropdown
    $.each(config.global.entities, function() {

        // Skip the current one
        if(this.name !== config.current.name) {

            $('.flow-header__actions-menu_entity-list').append(
                '<li class="flow-header__actions-menu_entity-item">' +
                    '<button data-entity="' + this.name + '">' +
                        '<strong>' + this.name + '</strong>' +
                    '</button>' +
                '</li>'
            );
        }
    });

    // Fill the current date in the first button of the subnav and set the marker
    $('.flow-header__links-menu').find('.flow-header__links-menu_item').first().find('a').html(months[config.global.date.month] + ' ' + config.global.date.year);
    adjust_top_menu_marker_to_item($('.flow-header__links-menu_item--current'), false);

    // Append the data to the top summary section
    $('.flow-section__summary').find('[data-append]').each(function() {

        if(
            eval('config.' + $(this).attr('data-append').replace('\'].total', '\']').replace('.deposited', '')) !== undefined &&
            eval('config.' + $(this).attr('data-append').replace('\'].total', '\']')) !== undefined
        ) {
            
            $(this).html(Number(eval('config.' + $(this).attr('data-append'))).toLocaleString(
                undefined, { 
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                }
            ));

        } else {
            $(this).html('0');
        }
    });

    // Set the colors to the top summary section's data
    $('.flow-section__summary').find('.flow-section__summary-column--lg').addClass(
        (config.current.data.aggregated.totals.balance >= 0 ? 'is-positive' : 'is-negative')
    );

    $('.flow-section__summary').find('.flow-section__summary-cell_deposit').each(function() {

        var deposited_obj = $(this).find('[data-append]').attr('data-append'),
            total_obj     = $(this).parents('.flow-section__summary-cell').find('> bdi').find('[data-append]'),
            deposited     = 0,
            total         = 0;

        if(deposited_obj && deposited_obj.length > 0) {

            if(
                eval('config.' + deposited_obj.replace('\'].total', '\']').replace('.deposited', '')) && 
                eval('config.' + deposited_obj.replace('\'].total', '\']'))
            ) {
                deposited = Number(eval('config.' + deposited_obj.attr('data-append')));
            }
    
            if(
                eval('config.' + total_obj.attr('data-append').replace('\'].total', '\']').replace('.deposited', '')) && 
                eval('config.' + total_obj.attr('data-append').replace('\'].total', '\']'))
            ) {
                total = Number(eval('config.' + total_obj.attr('data-append')));
            }
    
            $(this).addClass(
                (deposited < total ? 'is-negative' : 'is-positive')
            );    
        }
    });

    // Build the detail rows
    // First, go over the items in the data array and insert them into the config per-day object
    organize_data_rows_by_day();

    // Then, start building the days HTML
    var detailed_html = [],
        total_balance = config.current.data.aggregated.prev_transferred.sum; // Used to show the balance changes after each transaction

    // Build the sum transfer line
    detailed_html.push('<div class="flow-section__detailed-day__item flow-section__detailed-day__item--transfer">' +

        '<div class="container">' +
            '<div class="flow-section__detailed-day__item-cell"></div>' +

            '<div class="flow-section__detailed-day__item-cell ' + (config.current.data.aggregated.prev_transferred.sum < 0 ? 'is-negative' : 'is-positive') + '">' +
                '<bdi>₪' + (config.current.data.aggregated.prev_transferred.sum < 0 ? '-' : '') + (Number(config.current.data.aggregated.prev_transferred.sum).toLocaleString(
                    undefined, { 
                        minimumFractionDigits: 2
                    }
                )) + '</bdi>' +
            '</div>' +

            '<div class="flow-section__detailed-day__item-cell">' +
                '<svg xmlns="http://www.w3.org/2000/svg" width="16.29" height="15" viewBox="0 0 16.29 15">' +
                    '<path id="noun-change-3266509" d="M77,18.6a.5.5,0,0,0-.349.863L77.684,20.5H69.162a3.053,3.053,0,0,0-3.05,3.05,3.225,3.225,0,0,0,3.28,3.055h9.959a2.046,2.046,0,1,1,0,4.091H71.068L72.1,29.665a.5.5,0,1,0-.712-.712L69.5,30.847a.5.5,0,0,0,0,.712L71.39,33.45a.5.5,0,1,0,.712-.708L71.064,31.7h8.288a3.05,3.05,0,1,0,0-6.1H69.393s-2.276-.961-2.276-2.05a2.029,2.029,0,0,1,2.046-2.046h8.518l-1.035,1.034a.5.5,0,1,0,.708.712l1.895-1.894a.5.5,0,0,0,0-.708l-1.895-1.895A.5.5,0,0,0,77,18.605Z" transform="translate(-66.112 -18.605)" fill="#a8c0c1"/>' +
                '</svg>' +
                '<span>' + config.current.data.aggregated.prev_transferred.desc + '</span>' +
            '</div>' +
        '</div>' +
    '</div>');

    // Use an array that contains the each row's timestamp key to make
    // sure that the rows are sorted by date DESC
    var all_dates = Object.keys(config.current.data.aggregated.days);

    all_dates.sort(function (a, b) {
        return Number(a) - Number(b);
    });

    $.each(all_dates, function() {

        var list_date           = new Date(Number(this)),
            list_month          = list_date.getMonth() + 1,
            list_date_formatted = 'יום ' + days[list_date.getDay()] + ', ' + list_date.getDate() + ' ב' + months[list_month.toString().padStart(2, '0')] + ' ' + list_date.getFullYear();

        // Build the days list HTML
        var day_list_html = [];

        $.each(config.current.data.aggregated.days[this], function() {

            // Add / subtract the current sum from the total balance
            total_balance = (this['זכות / חובה'] == 'חובה' ? total_balance - (this['סכום לאחר מס'] ? Number(this['סכום לאחר מס']) : Number(this['סכום'])) : total_balance + (this['סכום לאחר מס'] ? Number(this['סכום לאחר מס']) : Number(this['סכום'])));

            // Build the HTML for that row
            day_list_html.push('<li class="flow-section__detailed-day__item"' +
                'data-status="' + this['סטטוס'] + '"' +
                '>' +

                '<button class="flow-section__detailed-day__item__mobile-delete">' +
                    '<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12.002" viewBox="0 0 12 12.002">' +
                        '<path data-name="Path 466" d="m143.44 84.9 5.52-5.521-5.52-5.521.479-.479 5.52 5.521 5.521-5.52.479.479-5.521 5.52 5.52 5.521-.479.479-5.52-5.521-5.521 5.52z" transform="translate(-143.44 -73.382)" style="fill:#fff"/>' +
                    '</svg>' +
                '</button>' +

                '<div class="container">' +
                    '<div class="flow-section__detailed-day__item-cell">' +
                        (this['הופקד לפקדון?'] != '' ? (this['הופקד לפקדון?'] == 'כן' || this['סטטוס'] == 'שולם' ? '<svg xmlns="http://www.w3.org/2000/svg" width="11.595" height="15.492" viewBox="0 0 11.595 15.492">' +
                            '<g id="noun-locked-5278370" transform="translate(-219.125 -98.003)">' +
                                '<path id="Path_474" data-name="Path 474" d="M230.092,103.992h-1.2v-2.02a3.969,3.969,0,1,0-7.938,0v2.02h-1.2a.625.625,0,0,0-.625.625v8.253a.625.625,0,0,0,.625.625h10.345a.625.625,0,0,0,.625-.625v-8.253a.629.629,0,0,0-.627-.625Zm-2.444,0h-5.459v-2.02a2.729,2.729,0,1,1,5.459,0Z" fill="#cadcdd"/>' +
                            '</g>' +
                        '</svg>' : '<svg xmlns="http://www.w3.org/2000/svg" width="15.621" height="15.663" viewBox="0 0 15.621 15.663">' +
                            '<g id="noun-unlocked-5278414" transform="translate(-171.473 -75.938)">' +
                                '<path id="Path_514" data-name="Path 514" d="M183.31,75.938a3.788,3.788,0,0,0-3.784,3.784v2.82h-7.457a.6.6,0,0,0-.6.6V91a.6.6,0,0,0,.6.6h9.861a.6.6,0,0,0,.6-.6V83.138a.6.6,0,0,0-.6-.6H180.7v-2.82a2.6,2.6,0,1,1,5.21,0v2.032h1.179V79.722a3.789,3.789,0,0,0-3.784-3.784Z" transform="translate(0 0)" fill="#a8c0c1"/>' +
                            '</g>' +
                        '</svg>') : '') +
                    '</div>' +
    
                    '<div class="flow-section__detailed-day__item-cell ' + (this['זכות / חובה'] == 'חובה' ? 'is-negative' : 'is-positive') + '">' +
                        '<bdi>₪' + (this['זכות / חובה'] == 'חובה' ? '-' : '') + (Number(this['סכום']).toLocaleString(
                            undefined, { 
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            }
                        )) + '</bdi>' +
    
                        '<small class="' + (total_balance >= 0 ? 'is-positive' : 'is-negative') + '">' +
                            '<bdi>₪' + 
                                (total_balance.toLocaleString(
                                    undefined, { 
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    }
                                )) + 
                            '</bdi>' +
                        '</small>' +
                    '</div>' +
    
                    '<div class="flow-section__detailed-day__item-cell">' +
                       '<span>' + this['תיאור'].toString() + '</span>' +
                    '</div>' +
    
                    '<div class="flow-section__detailed-day__item-cell">' +
                        '<span>' + this['גורם'].toString() + '</span>' +
                    '</div>' +
    
                    '<div class="flow-section__detailed-day__item-cell">' +
                        '<span><span>' + (Object.values(config.global.payment_methods).find(o => o.name === this['אמצעי תשלום']).emoji) + '</span> ' + this['אמצעי תשלום'].toString() + '</span>' +
                        (this['מס׳ המחאה'] != '' ? '<small>מס׳ ' + this['מס׳ המחאה'].toString() + '</small>' : '') +
                    '</div>' +
    
                    '<div class="flow-section__detailed-day__item-cell">' +
                        '<span>' + (this['מס׳ תשלום'] != '' ? (this['מס׳ תשלום'].toString() + ' מתוך ' + this['סך תשלומים'].toString()) : '') + '</span>' +
                    '</div>' +

                    '<div class="flow-section__detailed-day__item-cell">' +
                        '<span><span>' + (Object.values(config.global.categories).find(o => o.name === this['קטגוריה ראשית']).emoji) + '</span> ' + this['קטגוריה ראשית'].toString() + '</span>' +
                    '</div>' +
    
                    '<div class="flow-section__detailed-day__item-cell">' +
                        '<span><span>' + (Object.values(config.global.categories).find(o => o.name === this['קטגוריה משנית']).emoji) + '</span> ' + this['קטגוריה משנית'].toString() + '</span>' +
                    '</div>' +
    
                    '<div class="flow-section__detailed-day__item-cell">' +
                        '<button class="flow-section__detailed-day__item-cell_remove">' +
                            '<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12.002" viewBox="0 0 12 12.002">' +
                                '<path data-name="Path 466" d="m143.44 84.9 5.52-5.521-5.52-5.521.479-.479 5.52 5.521 5.521-5.52.479.479-5.521 5.52 5.52 5.521-.479.479-5.52-5.521-5.521 5.52z" transform="translate(-143.44 -73.382)" style="fill:#a8c0c1"/>' +
                            '</svg>' +
                        '</button>' +
                    '</div>' +
                '</div>' +
            '</li>');
        });

        // Build the day wrapper and append the days list HTML inside
        detailed_html.push('<div class="flow-section__detailed-day">' +
            '<h5>' +
                '<div class="container">' +
                    list_date_formatted +
                '</div>' +
            '</h5>' +
    
            '<ul class="flow-section__detailed-day__list">' +
                day_list_html.reverse().join('') +
            '</ul>' +
        '</div>');
    });

    // Append the HTML to the detailed section
    $('.flow-section__detailed').html(detailed_html.reverse().join(''));

    // Run the callback function
    callback();
}

// Insert all of the data rows organized by day to the config days object
function organize_data_rows_by_day() {

    $.each(config.current.data[config.global.date.month], function() {

        // If the day array doesn't exist in the config dates object, create it first
        if(
            ! config.current.data.aggregated.days[strtotime(this['תאריך תשלום'])] ||
            config.current.data.aggregated.days[strtotime(this['תאריך תשלום'])].length <= 0
        ) {
            config.current.data.aggregated.days[strtotime(this['תאריך תשלום'])] = [];
        }

        // Then, append the row into that item
        config.current.data.aggregated.days[strtotime(this['תאריך תשלום'])].push(this);
    });
}

// Make a request on a specific Google Sheet to fetch it's contents
function fetch_google_sheet_contents_by_id(id, append_to, range = null) {

    var data = [];

    $.ajax({
        url: 'https://sheets.googleapis.com/v4/spreadsheets/' + id + '?fields=sheets.properties.title,sheets.properties.sheetId' + (range === null ? ',sheets.data.rowData.values.userEnteredValue' : ''),
        type: 'GET',
        async: false,
        beforeSend: function(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + Cookies.get('flow-google-token'));
        },
        error: function(data) {
            console.log(data);
        },
        success: function(resp) {
            
            if(resp.sheets && resp.sheets.length > 0) {

                // If a range was set, call the API again and go over them using the structure returned from the API
                if(range !== null) {

                    var sheet_title = range.split('!')[0],
                        keys;

                    eval(append_to)[sheet_title] = {};
    
                    // Call the API again with the range to get the values
                    $.ajax({
                        url: 'https://sheets.googleapis.com/v4/spreadsheets/' + id + '/values/' + range,
                        type: 'GET',
                        async: false,
                        beforeSend: function(xhr) {
                            xhr.setRequestHeader('Authorization', 'Bearer ' + Cookies.get('flow-google-token'));
                        },
                        error: function(data) {
                            console.log(data);
                        },
                        success: function(data_resp) {

                            $.each(data_resp.values, function(key) {
                
                                // Skip the first row as it's only used for the sheet header
                                if(key !== 0) {

                                    // Create the row object to append the data to
                                    eval(append_to)[sheet_title][key] = {};

                                    $.each(this, function(cell_key) {
                                        eval(append_to)[sheet_title][key][keys[cell_key]] = this.toString();
                                    });
                                    
                                } else {
                                    keys = this;
                                }
                            });
                        }
                    });
                }
                
                // If a range wasn't set, go over the data retrieved from the API main call
                else {

                    $.each(resp.sheets, function() {

                        var sheet_title = this.properties.title,
                            keys;
                
                        eval(append_to)[sheet_title] = {};
                
                        $.each(this.data[0].rowData, function(key) {
                            
                            // Skip the first row as it's only used for the sheet header
                            if(key !== 0) {
                
                                // Create the row object to append the data to
                                eval(append_to)[sheet_title][key] = {};
                
                                $.each(this.values, function(cell_key) {
                                    eval(append_to)[sheet_title][key][keys[cell_key].userEnteredValue.stringValue] = this.userEnteredValue.numberValue ? this.userEnteredValue.numberValue : this.userEnteredValue.stringValue;
                                });
                                
                            } else {
                                keys = this.values;
                            }
                        });
                    });                
                }
            }
        }
    });

    return data;
}

// Top menu links - move the marker to the currently hovered element
$('.flow-header__links-menu_item').on('mouseenter', function() {
    adjust_top_menu_marker_to_item($(this), true);
});

// Top menu links - move the marker back to the original 
// element when leaving the hovered element's hitbox
$('.flow-header__links-menu_item').on('mouseleave', function() {
    adjust_top_menu_marker_to_item($('.flow-header__links-menu_item--current'), true);
});

// Top menu - hide on page load if the window's top offset is > 0
$(document).ready(function() {

    if($(window).scrollTop() > 0) {
        $('.flow-header__links').addClass('flow-header__links--collapsed');
    }
});

// Top menu - collapse on scroll down and show on scroll up
var last_scroll_pos = 0;

$(window).on('scroll', function() {

    if($(window).scrollTop() > 0) {

        if($(this).scrollTop() > last_scroll_pos) {
            $('.flow-header__links').addClass('flow-header__links--collapsed');
        } else {
            $('.flow-header__links').removeClass('flow-header__links--collapsed');
        }

    } else {
        $('.flow-header__links').removeClass('flow-header__links--collapsed');
    }

    last_scroll_pos = $(this).scrollTop();
});

// Helper - move and adjust the width of the top marker
function adjust_top_menu_marker_to_item(item, animate) {

    var marker = $('.flow-header__links-menu_marker');

    if(animate) {
        
        marker.stop().animate({
            width: item.outerWidth(),
            left: item.offset().left - $('.flow-header__links-menu').offset().left
        }, 250);
    
        } else {

        marker.css({
            width: item.outerWidth(),
            left: item.offset().left - $('.flow-header__links-menu').offset().left
        });

    }
}

// Helper - get a timestamp from a date, similar to PHP
function strtotime(date) {
    date = date.split(' ');

    if(date.length === 2) {
        time = date[1].split(':');
        date = date[0].split('.');

        return new Date(date[2], date[1] - 1, date[0], time[0], time[1]).getTime();
    }

    date = date[0].split('.');

    return new Date(date[2], date[1] - 1, date[0]).getTime();
}

// Entity switching logic - display the dropdown on entity click
$('.flow-header__actions-menu_entity').find('.flow-header__actions-menu_entity-meta').find('button').on('click', function() {
    $('.flow-header__actions-menu_entity-list').slideToggle(200).toggleClass('is-visible');

    if($('.flow-header__actions-menu_entity-list').hasClass('is-visible')) {
        $(this).text('הסתר רשימה');
    } else {
        $(this).text('החלף יישות');
    }
});

// When clicking outside the entities list, hide it
$('body').on('click', function(e) {

    if(
        ! $(e.target).hasClass('flow-header__actions-menu_entity') &&
        (
            ! $(e.target).parents('.flow-header__actions-menu_entity') ||
            $(e.target).parents('.flow-header__actions-menu_entity').length <= 0
        ) &&
        $('.flow-header__actions-menu_entity-list').hasClass('is-visible')
    ) {
        $('.flow-header__actions-menu_entity-list').slideUp(200).removeClass('is-visible');
        $('.flow-header__actions-menu_entity').find('.flow-header__actions-menu_entity-meta').find('button').text('החלף יישות');
    }
});

// On entity selection in the dropdown, set the cookie to the entity selected and reload the page
$('.flow-header__actions-menu_entity').on('click', '.flow-header__actions-menu_entity-item button', function(e) {

    Cookies.set('flow-current-app', $(this).attr('data-entity'), { 
        expires: 365, 
        path: '/' 
    });

    window.location.reload();
});

// Mobile - reveal and hide the remove button on task left swipe
handleSwipe('.flow-section__detailed', '.flow-section__detailed-day__item', [
    SWIPE_LEFT,
    SWIPE_RIGHT,
], function (direction, element) {

    if(direction !== 'CLICK') {

        if(direction == 'SWIPE_LEFT' && $(window).width() <= 1024) {

            // Toggle the current one
            element.addClass('remove-open');

            // Hide the rest of the open toggles
            $('.flow-section__detailed-day__item').not(element).removeClass('remove-open');
        }

        if(direction == 'SWIPE_RIGHT' && $(window).width() <= 1024) {

            // Hide the remove button
            element.removeClass('remove-open');
        }
    }
});